// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-flood-defence-js": () => import("./../../../src/pages/flood-defence.js" /* webpackChunkName: "component---src-pages-flood-defence-js" */),
  "component---src-pages-godalming-greenway-js": () => import("./../../../src/pages/godalming-greenway.js" /* webpackChunkName: "component---src-pages-godalming-greenway-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-response-flood-policy-js": () => import("./../../../src/pages/our-response/flood-policy.js" /* webpackChunkName: "component---src-pages-our-response-flood-policy-js" */),
  "component---src-pages-our-response-management-plan-js": () => import("./../../../src/pages/our-response/management-plan.js" /* webpackChunkName: "component---src-pages-our-response-management-plan-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-visitor-demographics-js": () => import("./../../../src/pages/visitor-demographics.js" /* webpackChunkName: "component---src-pages-visitor-demographics-js" */),
  "component---src-pages-wildlife-js": () => import("./../../../src/pages/wildlife.js" /* webpackChunkName: "component---src-pages-wildlife-js" */)
}

